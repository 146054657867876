<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row class="filterSection">
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('doctor.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-row class="mt-4">
            <b-col md="3" v-for="(item, index) in patients" :key="index">
                <b-card class="text-center">
                    {{item.name}}
                    <div v-if="!item.nontc"><small>( TC : {{item.identity}} )</small></div>
                    <b-card-footer>
                        <b-button variant="primary" v-b-tooltip.hover :title="$t('general.receipes')" pill size="sm" @click="getPatientRecipe(item.uuid)">
                            <i class="fas fa-receipt"></i>
                        </b-button>
                        <b-button variant="success" v-b-tooltip.hover :title="$t('general.reports')" pill size="sm" @click="getPatientReport(item.uuid)">
                            <i class="far fa-file-alt"></i>
                        </b-button>
                        <b-button variant="default" v-b-tooltip.hover :title="$t('general.files')" pill size="sm" @click="getPatientFile(item.uuid)">
                            <i class="fas fa-folder-open"></i>
                        </b-button>
                        <b-button variant="info" v-b-tooltip.hover :title="$t('general.notes')" pill size="sm" @click="handleNote(item.uuid, index)">
                            <i class="fas fa-sticky-note"></i>
                        </b-button>
                        <b-button variant="warning" v-b-tooltip.hover :title="$t('general.comments')" pill size="sm" @click="getComment(item.uuid)">
                            <i class="fas fa-comments"></i>
                        </b-button>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-card v-if="pages>1&&!loading" class="mt-4">
            <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
        </b-card>

        <modal :show.sync="processModal.modal" :showClose="false" :backdropClose="false" @close="modalClose" size="lg">
            <template v-if="processModal.id=='file'">
                <h6 slot="header" class="modal-title">{{$t('general.file_list')}}</h6>
                <b-alert variant="info" :show="files.length===0">{{$t('info.no_files')}}</b-alert>
                <b-row>
                    <b-col md="3" class="mb-4" v-for="(item, index) in files" :key="index">
                        <div class="file-section">
                            <div class="file-name">
                                <a :title="item.originalName">{{item.originalName}}</a>
                            </div>
                            <div class="file-date">{{item.createdAt | getDate}}</div>
                            <div class="file-icon">
                                <i class="fas fa-file-image" v-if="item.type=='image'"></i>
                                <i class="fas fa-file-alt" v-else></i>
                            </div>
                            <div class="file-footer">
                                <base-button type="primary" size="sm" @click="openFile(item.uuid)">{{$t('general.open')}}</base-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <template v-if="processModal.id=='note'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.note_list')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in noteData.data" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p>{{item.note}}</p>
                    <small>{{item.createdAt | getDate}}</small>
                </b-alert>
                <b-alert variant="info" :show="noteData.data.length===0">{{$t('info.no_notes')}}</b-alert>
            </template>
            <template v-if="processModal.id=='recipe'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.recipe_list')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in recipes" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p v-html="$options.filters.getHtmlText(item.text)"></p>
                    <small>{{item.createdAt | getDate}}</small>
                </b-alert>
                <b-alert variant="info" :show="recipes.length===0">{{$t('info.no_recipe')}}</b-alert>
            </template>
            <template v-if="processModal.id=='report'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.report_list')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in reports" :key="index">
                    <small>
                        <strong>{{$t('general.appointment_info')}} : </strong>{{item.appointment.data.date | getIntDate}} {{item.appointment.data.time | getIntTime}} - {{$t('availability.'+item.appointment.data.type)}}</small>
                    <hr class="my-2">
                    <template v-if="userType=='admin'">
                        <strong>{{item.doctor.title}} {{item.doctor.name}}</strong><br>
                    </template>
                    <p v-html="$options.filters.getHtmlText(item.text)"></p>
                    <small>{{item.createdAt | getDate}}</small>
                </b-alert>
                <b-alert variant="info" :show="reports.length===0">{{$t('info.no_reports')}}</b-alert>
            </template>
            <template v-if="processModal.id=='comment'" v-loading="processModal.loading">
                <h6 slot="header" class="modal-title">{{$t('general.appointment_comment')}}</h6>
                <b-alert class="posrel" variant="secondary" show v-for="(item, index) in comments" :key="index">
                    <strong>{{item.patient.name}}</strong>
                    <div class="patient-rate">
                        <b-input-group class="rateInput mb-3">
                            <b-form-rating :value="item.rate" color="#ff8800" inline readonly no-border></b-form-rating>
                        </b-input-group>
                    </div>
                    <p class="mt-2">
                        <small v-html="$options.filters.getHtmlText(item.comment)"></small>
                    </p>
                    <small>{{item.createdAt | getDate}}</small>
                    <template v-if="item.doctor_comment&&item.doctor_comment!=''">
                        <hr class="mt-2">
                        <strong>{{$t('general.your_answer')}} : </strong>
                        <small v-html="$options.filters.getHtmlText(item.doctor_comment)"></small>
                    </template>
                </b-alert>
                <b-alert variant="info" :show="comments.length===0">{{$t('info.no_comments')}}</b-alert>
            </template>
            <template slot="footer">
                <base-button type="link" class="text-danger ml-auto" @click="modalClose">{{$t('general.close')}}</base-button>
            </template>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { PATIENT_REQUEST, PATIENT_UPDATE, PATIENT_ADD, PATIENT_DELETE, PATIENT_FILES, RECIPE_REQUEST, REPORT_REQUEST, COMMENT_LIST_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { getCookie } from '@/utils/cookie';
let self;

const token = getCookie('user-token');
const session = getCookie('user-session');
import config from '@/config'
export default {
    computed: {
        ...mapGetters({
            patients: 'getPatients',
            total: 'getPatientTotal',
            pages: 'getPatientPages',
            files: 'getFiles',
            userType: 'getUserType',
            recipes: 'getRecipes',
            reports: 'getReports',
            comments: 'getComments'
        })
    },
    data() {
        return {
            noteData: {
                text: '',
                show: false,
                data: {}
            },
            processModal: {
                appointment: {},
                loading: false,
                status: false,
                index: false,
                modal: false,
                data: {},
                id: '',
                type: '',
                payment: ''
            },
            loading: true,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {}
        };
    },
    methods: {
        showNote() {
            self.noteData.show = true;
        },
        showRecipe() {
            self.recipeData.show = true;
        },
        showReport() {
            self.reportData.show = true;
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        },
        doProcess(process) {
            self.processModal.status = false;
            let row = self.patients[self.processModal.index];
            switch (process) {
                case 'edit':
                    self.handleEdit(row);
                    break;
                case 'delete':
                    self.handleDelete(row.uuid);
                    break;
                case 'note':
                    self.handleNote(row.uuid, self.processModal.index);
                    break;
                default:
                    break;
            }
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        handleNote(uuid, index) {
            self.processModal.id = 'note';
            self.processModal.modal = true;
            self.processModal.loading = true;
            self.$store
                .dispatch('PATIENT_NOTE_REQUEST', { uuid, index })
                .then(resp => {
                    self.noteData.data = self.patients[index].notes !== undefined ? self.patients[index].notes : [];
                    self.processModal.loading = false;
                })
                .catch(err => {
                    self.processModal.loading = false;
                    self.processModal.modal = false;
                    self.noteData.data = null;
                    handleError(self, err);
                });
        },
        modalClose() {
            self.noteData = {
                text: '',
                show: false,
                data: {}
            };
            self.processModal = {
                appointment: {},
                loading: false,
                status: false,
                index: false,
                modal: false,
                data: {},
                id: '',
                type: '',
                payment: ''
            };
        },
        getData() {
            self.$store
                .dispatch(PATIENT_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        getPatientFile(uuid) {
            self.processModal.id = 'file';
            self.processModal.modal = true;
            self.processModal.loading = true;
            self.$store
                .dispatch(PATIENT_FILES, { patient: uuid })
                .then(resp => {
                    self.processModal.loading = false;
                })
                .catch(err => {
                    self.processModal.loading = false;
                    handleError(self, err);
                });
        },
        openFile(uuid) {
            var xhr = new XMLHttpRequest();

            xhr.open('GET', config.VUE_APP_CDNURL + '/' + uuid);
            xhr.onreadystatechange = () => {
                if (xhr.status == 200 && xhr.response) {
                    var data_url = URL.createObjectURL(xhr.response);
                    window.open(data_url);
                }
            };
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', token);
            xhr.setRequestHeader('Session', session);
            xhr.send();
        },
        getPatientRecipe(uuid) {
            return new Promise((resolve, reject) => {
                self.processModal.id = 'recipe';
                self.processModal.modal = true;
                self.processModal.loading = true;
                self.$store
                    .dispatch(RECIPE_REQUEST, {
                        filter: { patient: uuid },
                        pagination: { limit: 10000 }
                    })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getPatientReport(uuid) {
            return new Promise((resolve, reject) => {
                self.processModal.id = 'report';
                self.processModal.modal = true;
                self.processModal.loading = true;
                self.$store
                    .dispatch(REPORT_REQUEST, {
                        filter: { patient: uuid },
                        pagination: { limit: 10000 }
                    })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        },
        getComment(uuid) {
            return new Promise((resolve, reject) => {
                self.processModal.id = 'comment';
                self.processModal.modal = true;
                self.processModal.loading = true;
                self.$store
                    .dispatch(COMMENT_LIST_REQUEST, { filter: { patient: uuid } })
                    .then(resp => {
                        self.processModal.loading = false;
                        resolve(true);
                    })
                    .catch(err => {
                        self.processModal.loading = false;
                        handleError(self, err);
                        reject(true);
                    });
            });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
